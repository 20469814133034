import phoenixTheme from "workfront-tailwind-base/phoenixTheme.js";
import spectrumTheme from "workfront-tailwind-base/spectrumTheme.js";

export const getTheme = () => {
  return mergeUndefinedProperties(spectrumTheme, phoenixTheme);
}

function mergeUndefinedProperties(objectTo, objectFrom) {
  return Object.entries(objectFrom).reduce((acc, [key, value]) => {
    if (acc[key] === undefined) {
      acc[key] = value
    } else if (typeof acc[key] === 'object' && typeof value === 'object') {
      acc[key] = mergeUndefinedProperties(acc[key], value)
    }

    return acc
  }, { ...objectTo })
}
