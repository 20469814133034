import { injectGlobal } from "./configuration.js";
import { injectGlobalsAndResets } from './constants.js';

/**
 * These styles are automatically injected into the document when this MFE is loaded
 * and the HTML element has the `data-wf-style-reset` attribute (in Workfront NWE).
 */
const autoInjectGlobalStyles = `
  :root {
    --adobe-gray-75: #fafafa;
    --adobe-gray-100: #f5f5f5;
    --adobe-gray-200: #eaeaea;
    --adobe-gray-300: #e1e1e1;
    --adobe-gray-400: #cacaca;
    --adobe-gray-500: #b3b3b3;
    --adobe-gray-700: #6e6e6e;
    --adobe-gray-800: #4b4b4b;
    --adobe-gray-900: #2c2c2c;
    --adobe-blue-500: #1473e6;
    --adobe-blue-600: #0d66d0;
    --tw-shadow-color: #0d66d0;
  }

  .adobe-clean-regular-grey-800 {
    font-weight: normal;
    color: var(--adobe-gray-800);
  }

  .adobe-clean-bold-grey-800 {
    font-weight: bold;
    color: var(--adobe-gray-800);
  }

  html,
  div,
  body {
    box-sizing: border-box;
    font-size: 100%;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
  }

  :focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px var(--tw-shadow-color);
  }

  .spectrum-focus-ring {
    border-radius: 16px;
    position: relative;
  }

  .spectrum-focus-ring:focus,
  [class*='spectrum-Tabs-item'] {
    box-shadow: none !important;
  }

  .spectrum-focus-ring:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0px;
    border-radius: 18px;
    box-shadow: 0px 0px 0px 2px transparent;
    transition: all 130ms ease-out;
  }

  .spectrum-focus-ring:focus-visible:after {
    margin: -4px;
    box-shadow: 0px 0px 0px 2px var(--tw-shadow-color);
  }

  a:focus {
    box-shadow: none;
  }

  :focus:not(:focus-visible),
  button:focus,
  button:focus-visible {
    outline: none;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .blurred {
    filter: blur(15px);
    position: fixed;
  }

  svg {
    /* Tailwind's reset does block styling which conflicts with existing QS base styles */
    display: inline;
    vertical-align: baseline;
  }

  html button,
  div button {
    /* PHX had this as the line-height for buttons */
    line-height: normal;
    /* Tailwind sets this to 100%, previously we used this value from the user agent stylesheet,
     * fixes some button alignment problems where things are off by 1px */
    font-size: 13.3333px;
    font-family: inherit;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    background-color: transparent;
    background-image: none;
    border-width: 0px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  img {
    /* Tailwind sets this to 100%, which causes things like avatars with ellipsed names to collapse */
    max-width: initial;
  }

  body > div[class*='spectrum'] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 4;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  #main-content {
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding: 0;
    position: relative;
    flex: 1 1 0%;
  }

  #page-contents-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
  }

  #assistant-siderail-container {
    flex-shrink: 0;
  }

  #page-wrapper {
    display: flex;
    flex: 1 1 0%;
    height: 100%;
    overflow: hidden;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    z-index: 0;
  }

  #page-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    overflow: auto;
  }

  #page-content > div:empty {
    display: none;
  }

  #page-sidebar {
    position: relative;
  }

  #page-siderail {
    background-color: white;
    bottom: 0;
    filter: drop-shadow(0px 14px 24px rgba(70, 70, 70, 0.12))
      drop-shadow(0px 8px 19px rgba(70, 70, 70, 0.08))
      drop-shadow(0px 5px 8px rgba(70, 70, 70, 0.06));
    overflow: hidden scroll;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    &:empty {
      display: none;
    }
  }

  #page-header {
    background-color: var(--adobe-gray-75);
  }

  #page-toolbar {
    border-bottom: 1px solid rgb(224, 225, 227);
    border-top: 1px solid rgb(224, 225, 227);
    display: flex;
    justify-content: space-between;
    height: 41px;
    align-items: center;
  }

  #page-toolbar:empty {
    display: none;
  }

  .adobe-navbar {
    height: 48px;
  }
  .adobe-waffle {
    padding: 2.25rem 2rem;
  }

  @media print {
    #root {
      display: block;
    }

    main {
      /**
        * Firefox treats "flex" layout as "unbreakable" when printing.
        * As a result, Firefox will just run them off the bottom of
        * the page when they are too long.
        */
      overflow: visible;
      display: initial;
    }
  }

  abbr[title] {
    text-decoration: none;
  }
`

injectGlobalsAndResets && injectGlobal(autoInjectGlobalStyles);
