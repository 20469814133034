import preloadList from "workfront-tailwind-base/list.js";
import { tw } from "./configuration.js";
import { injectGlobalsAndResets } from './constants.js'

const stringPreloadClasses = preloadList.filter(
  (str) => typeof str === "string"
);

const marginAndPaddingTypes = ["", "t", "r", "b", "l", "x", "y"];
const marginAndPaddingValues = [0, 1, 2, 3, 4, 6, 8, "px"];

let margins = [];
let paddings = [];

for (let spacingType of marginAndPaddingTypes) {
  for (let spacingValue of marginAndPaddingValues) {
    margins.push(`m${spacingType}-${spacingValue}`);
    paddings.push(`p${spacingType}-${spacingValue}`);
  }
}

const grayText = [100, 200, 300, 400, 500, 600, 700, 800, 900].map(
  (shade) => `text-gray-${shade}`
);
// preloading a few tw classes, this can be expanded upon later
injectGlobalsAndResets && tw([...stringPreloadClasses, ...margins, ...paddings, ...grayText].join(" "));
