/**
 * When this is set to `true`, we inject both Workfront-custom
 * and Tailwind's CSS reset (see https://tailwindcss.com/docs/preflight)
 * @type {boolean}
 */
export const injectGlobalsAndResets = document.documentElement.hasAttribute('data-wf-style-reset')

/**
 * When this is set to `true`, we tell twind to inject Tailwind CSS reset (see https://tailwindcss.com/docs/preflight).
 * When `injectGlobalsAndResets = true`, Tailwind CSS reset is always applied.
 * @type {boolean}
 */
export const injectTailwindCSSReset = document.documentElement.hasAttribute('data-wf-tailwind-css-reset')
