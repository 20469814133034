import { injectGlobal } from "./configuration.js";
import { injectGlobalsAndResets } from './constants.js'

const interopStyles = `
  div[class*="react-spectrum-Datepicker"] :focus {
    box-shadow: unset;
  }
`;

injectGlobalsAndResets && injectGlobal(interopStyles);
