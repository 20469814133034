import React from "react";

import { css, tw } from "./configuration.js";

// @twind/react isn't working with twind v1 yet
// replace this function with that once it is compatible
export function styled(Component) {
  return (styleFn) => {
    function StyledComponent(props) {
      let classNames = [css(styleFn(props))];

      if (props.className) {
        classNames.push(props.className);
      }

      return React.createElement(Component, {
        ...props,
        className: tw(`${classNames.join(" ")}`),
      });
    }

    return StyledComponent;
  };
}
