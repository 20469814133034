import {
  apply as apply$,
  css as css$,
  cssom,
  dom,
  injectGlobal as injectGlobal$,
  keyframes as keyframes$,
  style as style$,
  twind,
  tx as tx$,
} from "@twind/core"
import presetAutoprefix from "@twind/preset-autoprefix"
import presetExt from "@twind/preset-ext"
import tailwind from "@twind/preset-tailwind"

import { injectGlobalsAndResets, injectTailwindCSSReset } from "./constants.js"
import lineClamp from "./customRules/line-clamp.js"
import { getTheme } from "./theme.js"

const padding2x0 = css$("padding: 2px 0px;")
const padding1x3 = css$("padding: 1px 3px;")
const padding3x1 = css$("padding: 3px 1px;")
const lowShadow = css$("box-shadow: 0 1px 2px 0 rgba(0, 35, 64, 0.32);")

const sheetTarget = document.querySelector("#twind-css")

export const tw = /* @__PURE__ */ twind(
  {
    preflight: injectGlobalsAndResets ? true : injectTailwindCSSReset,
    presets: [presetAutoprefix(), tailwind(), presetExt()],
    theme: getTheme(),
    // some Phoenix rules the stylesheet MFE converted to TailwindCSS rules
    rules: injectGlobalsAndResets
      ? [
          [
            "h1",
            `@(text-gray-900 font-semibold text-3xl leading-open m-0 ${padding2x0})`,
          ],
          [
            "h2",
            `@(text-gray-800 font-semibold text-2xl leading-loose m-0 ${padding1x3})`,
          ],
          [
            "h3",
            `@(text-gray-800 font-extrabold text-xl leading-relaxed m-0 ${padding2x0})`,
          ],
          [
            "h4",
            `@(text-gray-600 font-bold text-lg leading-normal m-0 ${padding1x3})`,
          ],
          ["h5", `@(text-gray-700 font-semibold text-sm leading-snug m-0 p-0)`],
          [
            "a",
            `@(text-blue-400 font-semibold text-base leading-normal cursor-pointer no-underline m-0 ${padding2x0} hover:text-blue-600 focus:text-blue-600 visited:text-blue-600 active:text-blue-600)`,
          ],
          [
            "passiveLink",
            `@(text-gray-700 font-medium text-base leading-normal cursor-pointer no-underline hover:underline)`,
          ],
          [
            "fnt-lg",
            `@(text-gray-600 font-bold text-lg leading-normal m-0 ${padding2x0})`,
          ],
          [
            "textLarge",
            `@(text-gray-600 font-bold text-lg leading-normal m-0 ${padding2x0})`,
          ],
          [
            "fnt-mdm",
            `@(text-gray-700 font-medium text-base leading-normal m-0 ${padding2x0})`,
          ],
          [
            "text",
            `@(text-gray-700 font-medium text-base leading-normal m-0 ${padding2x0})`,
          ],
          [
            "fnt-sm",
            `@(text-gray-500 font-normal text-sm leading-snug m-0 p-0)`,
          ],
          [
            "textSmall",
            `@(text-gray-500 font-normal text-sm leading-snug m-0 p-0)`,
          ],
          [
            "fnt-xsm",
            `@(text-gray-400 font-bold text-xs leading-tight m-0 ${padding3x1})`,
          ],
          [
            "textMicro",
            `@(text-gray-400 font-bold text-xs leading-tight m-0 ${padding3x1})`,
          ],
          [
            "shadow-low",
            `@(border-solid border border-gray-50 rounded ${lowShadow})`,
          ],
          ["shadow-medium", `@(shadow-md rounded)`],
          ["shadow-high", `@(shadow-md rounded)`],
          ["box-shadow", "@(shadow-md)"],
          lineClamp,
        ]
      : [lineClamp],
  },
  process.env.NODE_ENV === "development" ? dom(sheetTarget) : cssom(sheetTarget)
)

export const apply = /* @__PURE__ */ apply$.bind(tw)
export const tx = /* @__PURE__ */ tx$.bind(tw)
export const injectGlobal = /* @__PURE__ */ injectGlobal$.bind(tw)
export const keyframes = /* @__PURE__ */ keyframes$.bind(tw)
export const css = /* @__PURE__ */ css$.bind(tw)
export const style = /* @__PURE__ */ style$.bind(tw)
